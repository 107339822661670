import styled from "styled-components"

export const ContainerInput = styled.div`
    width: ${props => `${props.Width}`};
    margin: ${props => `${props.Margin}`};
    label {
        font-size: 15px;
        font-weight: 600;
    }
`   

export const Input = styled.input`
    border:0px;
    border-bottom: 2px solid var(--primary-color);
    font-size: 18px;
    margin: 0px !important;
    border-bottom: 2px solid #444444;
    
    @media (max-width: 1180px) {
        width: 100%;
    }
`;
