import React from 'react';
import { ContainerInput, Input } from "./style.js";

const InputDefault = ({Width, Margin, Text, ...rest }) => {

  return (
      <>
        <ContainerInput Width={Width} Margin={Margin}>
          {Text &&
            <label>{Text}</label>
          }
          <Input style={{'width': '100%'}} {...rest}></Input>
        </ContainerInput>
      </>
  );
}

export default InputDefault;
