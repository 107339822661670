import { createGlobalStyle } from 'styled-components';
 
const GlobalStyle = createGlobalStyle`
    body {
        padding: 0px;
        margin: 0px;
        font-family: 'Arial' , Helvetica ,sans-serif;

        p {
            color: #000000;
            margin: 0px;
        }
        
        a {
            text-decoration: none;
            color: #000000;
        }

        a:hover {
            color: #000000;
        }

        .conShadown {
            // box-shadow: 0 3px 10px 0 rgb(0 0 0 / 15%);
        }
    
        .line-through {
            position: relative;
            color: #878787;
            font-size: 0.9em;
        }
        .line-through::after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            top: 50%;
            border-bottom: 2px solid #5f5f5f;
        }
    }
`;

 
export default GlobalStyle;

export const colors = {
    primary: "#e00408",
    secondary: "#d3455b",
    background1: "#2e894a",
    background2: "#fff",
  };