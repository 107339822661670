import styled from "styled-components"
import { colors } from "../../globalStyle";

export const ContainerInfoText = styled.div`
    background-color: ${colors.background2};
    background-position: top center;
    background-repeat: repeat;

    .container {
        background-color: #fff;
        padding: 15px;
    }

    padding-bottom: 60px;

    h2 {
        margin-top: 20px;
        font-size: 20px;
        font-weight: bold;
    }

    p {
        margin-bottom: 20px;
    }
`;

export const Title = styled.h1`
    text-align: center;
    padding-top: 20px;
    margin-top: 10px;
    font-weight: 600;
    font-size: 30px;
    color: #df0607;
`;

