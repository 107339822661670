import React from 'react';
import {Container} from "./style.js";

import { Link } from "react-router-dom";

import imgLogo from '../../imgs/logos/01.png';

const NotFound = (  ) => {
  
  document.title = "Datamais | Página não Encontrada"
  
  return (
    <>
      <Container>
        <div>
            <Link to="/">
                <img src={imgLogo}/>
            </Link>
            <h1>404</h1>
            <h2>Oooops... Página não encontrada!</h2>
        </div>
      </Container>
    </>
  );
}

export default NotFound;
