import styled from "styled-components"
import { colors } from "./../../globalStyle";

export const ContainerBanner = styled.div`
    background-color: ${colors.background2};   
    
    
    .container {
        background-color: #fff;
    }

    .detailsShop {
        background: #e5e5e5;
        padding: 50px 0px 50px 0px;
    }

    .detailsItem {
        span {
            font-size: 16px;
        }

        p {
            font-size: 15px;
        }

        span {
            font-weight: bold;
        }

        svg {
            font-size: 40px;
            margin-right: 15px;
        }

        background: #e5e5e5;
        display: flex;
        align-items: center;
    }
`;

