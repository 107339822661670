import styled from "styled-components"
import { colors } from "../../globalStyle";

export const ContainerHistory = styled.div`
    z-index: 2;

    p {
        background: #fff;
    }
`;
