import styled from "styled-components"

export const ContainerModal = styled.div`
    display: flex;

    h3{
        font-size: 20px;
        color: var(--primary-color);
    }
`

export const ListOperators = styled.div`

    overflow: scroll;
    max-height: 300px;

    div{
        margin-bottom: 10px;
    }

    label, input {
        cursor: pointer;
    }

`
