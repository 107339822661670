import { isChrome, isFirefox, isSafari, isOpera, isEdge, isIE } from 'react-device-detect';

export const getDevice = () => {
    let browserName = '';
  
  if (isChrome) {
    browserName = 'Google Chrome';
  } else if (isFirefox) {
    browserName = 'Mozilla Firefox';
  } else if (isSafari) {
    browserName = 'Apple Safari';
  } else if (isOpera) {
    browserName = 'Opera';
  } else if (isEdge) {
    browserName = 'Microsoft Edge';
  } else if (isIE) {
    browserName = 'Internet Explorer';
  } else {
    browserName = 'Outro';
  }

  return browserName;
};
