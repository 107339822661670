import { React, useState } from 'react';
import { Link } from "react-router-dom";

import { Card } from "./style.js";
import { formatCurrencyBRL } from "./../../utils/formatCurrencyBRL";
import { verifyLogin } from "./../../utils/verifyLogin";
import { FaRegEye } from "react-icons/fa";

import Col from 'react-bootstrap/Col';
import product from '../../imgs/products/01.png';

import not_found from '../../imgs/logos/not_found.png';

const ProductCard = ({ idDM005, description, name, price, promo, imgsJSON }) => {
  const [showDiv, setShowDiv] = useState(false);

  const handleMouseEnter = () => {
    setShowDiv(true);
  };

  const handleMouseLeave = () => {
    setShowDiv(false);
  };

  const overlayStyle = {
    opacity: showDiv ? "0.9" : "0",
    transition: "all 0.3s",
    // position: "relative",
    textAlign: "center",
    margin: "0px auto 0px auto",
    height: showDiv ? "120px" : "0px",
  };

  const searchStyle = {

    background: "#fff",
    color: "#2e894a",
    width: "60%",
    border: "2px solid #2e894a",
    borderRadius: "5px",
    padding: "6px",
    margin: "0px auto",
  };

  let imgURL = '';

  if (imgsJSON) {
    const parsedImgsJSON = JSON.parse(imgsJSON);

    let defaultImageFound = false;

    for (const colorObj of Object.values(parsedImgsJSON)) {
      if (colorObj.defaultImage) {
        imgURL = colorObj[colorObj.defaultImage.toString()];
        defaultImageFound = true;
        break;
      }
    }

    if (!defaultImageFound && parsedImgsJSON) {
      const chavesExteriores = Object.keys(parsedImgsJSON);
      if (chavesExteriores[0]) {
        const primeiraChaveExterior = chavesExteriores[0];
        const primeiroObjetoAninhado = parsedImgsJSON[primeiraChaveExterior];

        const chavesInteriores = Object.keys(primeiroObjetoAninhado);
        const primeiraChaveInterior = chavesInteriores[0];
        imgURL = primeiroObjetoAninhado[primeiraChaveInterior];
      }
    }
  }

  if (imgURL === '') {
    imgURL = not_found;
  }

  if (!description) {
    description = '-'
  }
  const calculateDiscount = (originalPrice, promotionalPrice) => {
    const discount = ((originalPrice - promotionalPrice) / originalPrice) * 100;
    return discount.toFixed(0);
  };

  return (
    <>
      <Col md={4} lg={3} translate="no">
        <Link to={`/product/${idDM005}/${description.replaceAll(' ', "-").replace(/\//g, ' | ')}`}>
          <Card
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            {
              verifyLogin() && promo && promo !== '0.0000' && promo !== undefined &&
              <>
                <div className='promoCard'>
                  <div className='promo'>
                    <p>{calculateDiscount(parseFloat(price), parseFloat(promo))}% <br /> OFF</p>
                  </div>
                </div>
              </>
            }
            <div className="imgsCard">
              <img src={imgURL} />
            </div>
            <div className="detailsCard">
            <h1 translate="no">
                {name}
                {verifyLogin() &&
                  <h2>
                    {
                      !promo || promo === '0.0000' ?
                        <span>{formatCurrencyBRL(price)}</span>
                        :
                        <>
                          <span className='line-through'>{formatCurrencyBRL(price)}</span>
                          &nbsp;&nbsp;
                          &nbsp;&nbsp;
                          <span style={{color: '#2e894a'}}>{formatCurrencyBRL(promo)}</span>
                        </>
                    }
                  </h2>
                }
              </h1>
            </div>
            <div style={overlayStyle}>
              <Link to={`/product/${idDM005}/${description.replaceAll(' ', "-").replace(/\//g, ' | ')}`} style={{ "position": "relative", "top": "70%" }}>
                <div style={searchStyle}>
                  <FaRegEye /> Espiar
                </div>
              </Link>
            </div>
          </Card>
        </Link>
      </Col>
    </>
  );
}

export default ProductCard;
