import React, { useState, useEffect } from 'react';
import api from "../../services/api";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { verifyLogin } from "./../../utils/verifyLogin";
import { formatCurrencyBRL } from "./../../utils/formatCurrencyBRL";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { ContainerOrders, Title } from "./style.js";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';
import ButtonDefault from "../../components/ButtonDefault";
import InputDefault from "../../components/InputDefault";
import SelectDefault from "../../components/SelectDefault";
import { FaBoxes, FaPrint } from "react-icons/fa";


const Orders = () => {

  document.title = "Atacado Militar - Meus Pedidos"

  const navigate = useNavigate();

  const [idDM001, setIdDM001] = useState(0);
  const [orderList, setOrderList] = useState([]);

  const [colorList, setColorList] = useState([]);
  const [sizeList, setSizeList] = useState([]);

  const [openIndex, setOpenIndex] = useState(null);

  const [statusFilter, setStatusFilter] = useState("");
  const [dateFromFilter, setDateFromFilter] = useState("");
  const [dateToFilter, setDateToFilter] = useState("");
  const [statusDateFromFilter, setStatusDateFromFilter] = useState("");
  const [statusDateToFilter, setStatusDateToFilter] = useState("");

  useEffect(() => {
    if (!verifyLogin()) {
      navigate(`/account`);
    }

    const lsClient = JSON.parse(localStorage.getItem('userDataLogin'))
    if(lsClient){
      if (lsClient.ID_DM001) {
        setIdDM001(lsClient.ID_DM001)
      } else {
        navigate(`/account `);
      }
    }

  }, []);

  useEffect(() => {
    api
      .get("get_all_sizes")
      .then((response) => {
        setSizeList(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    api
      .get("get_all_colors")
      .then((response) => {
        setColorList(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if(idDM001 !== 0  && orderList.length == 0){
      getOrdersClient()
    }

  }, [idDM001]);


  const getOrdersClient = () => {
    const dataOrdersFiltger = {
      "ID_DM001": idDM001,
      "IN_SITUACAO": statusFilter,
      "DT_PEDIDO_INICIAL": dateFromFilter,
      "DT_PEDIDO_FINAL": dateToFilter,
      "DT_SITUACAO_INICIAL": statusDateFromFilter,
      "DT_SITUACAO_FINAL": statusDateToFilter,
    }

  
    api
      .post("get_orders_filter", dataOrdersFiltger)
      .then((response) => {
        setOrderList(response.data)
      })
      .catch((err) => {
        console.log(err)
      });
  }

  const printerOrder = (orderId) => {
    console.log(orderId)
  }

  return (
    <>
      <Header />
      <ContainerOrders>
        <Container className="conShadown">
          <Title><FaBoxes/>Meus Pedidos</Title>
          <br />
          <Row>
            <Col xs={12} md={4}>
              <SelectDefault Text={'Status:'} value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)}>
                <option value="">Todos</option>
                <option value="0">Aguardando Aprovação</option>
                <option value="1">Aprovado</option>
                <option value="3">Cancelado</option>
                <option value="4">Não Aprovado</option>
                <option value="5">Enviado</option>
                <option value="6">Entregue</option>
              </SelectDefault>
            </Col>
            <Col xs={12} md={4}>
              <Row>
                <Col xs={6}>
                  <InputDefault type="date" Text={'Cadastro de:'} value={dateFromFilter} onChange={(e) => setDateFromFilter(e.target.value)} />
                </Col>
                <Col xs={6}>
                  <InputDefault type="date" Text={'Cadastro até:'} value={dateToFilter} onChange={(e) => setDateToFilter(e.target.value)} />
                </Col>
              </Row>
            </Col>
            <Col xs={12} md={4}>
              <Row>
                <Col xs={6}>
                  <InputDefault type="date" Text={'Status de:'} value={statusDateFromFilter} onChange={(e) => setStatusDateFromFilter(e.target.value)} />
                </Col>
                <Col xs={6}>
                  <InputDefault type="date" Text={'Status até:'} value={statusDateToFilter} onChange={(e) => setStatusDateToFilter(e.target.value)} />
                </Col>
              </Row>
            </Col>
            <Col xs={12} md={4}>
              <br/>
              <ButtonDefault Text={'Filtrar'} Width={'200px'} onClick={getOrdersClient}/>
            </Col>
          </Row>
          <hr/>
          <Row>
            <Col sm={12}>
              {orderList ?
                orderList.map((order, index) => {
                  const jsGrade = JSON.parse(order.JS_GRADE);
                  const date = new Date(order.DT_PEDIDO);
                  const day = String(date.getDate()).padStart(2, '0');
                  const month = String(date.getMonth() + 1).padStart(2, '0'); 
                  const year = date.getFullYear();
                  const formattedDate = `${day}/${month}/${year}`;
                  const address = `${order.NM_ENDERECO}, ${order.DS_LOGRADOURO}, ${order.NR_PREDIO}, ${order.NM_BAIRRO}, ${order.NM_CIDADE}, ${order.NR_CEP}, ${order.DS_REFERENCIA}`;

                  let orderTotal = 0;

                  let formattedDateStatus = '';

                  if(order.DT_SITUACAO){
                    const dateStatus = new Date(order.DT_SITUACAO);
                    const dayStatus = String(dateStatus.getDate()).padStart(2, '0');
                    const monthStatus = String(dateStatus.getMonth() + 1).padStart(2, '0'); 
                    const yearStatus = dateStatus.getFullYear();
                    formattedDateStatus = `${dayStatus}/${monthStatus}/${yearStatus}`;
                  }else{
                    formattedDateStatus = '-/-' 
                  }

                  return (
                    <>
                      <Row key={index} className='orderCard' onClick={() => setOpenIndex(openIndex === index ? null : index)}>
                        <Col xs={12}>
                          <p><strong>Pedido:</strong> #{order.ID_DM009} - {formattedDate}</p>
                          <p><strong>Endereço:</strong> {address}</p>
                          <p><strong>Status:</strong> {order.IN_SITUACAO === "0" ? "Aguardando Aprovação" : order.IN_SITUACAO === "1" ? 'Aprovado' : order.IN_SITUACAO === "3" ? 'Cancelado' : order.IN_SITUACAO === "4" ? 'Não Aprovado': order.IN_SITUACAO === "5" ? 'Enviado' : "Aguardando Aprovação"} - ({formattedDateStatus})</p>
                          <p><strong>Observação:</strong> {order.DS_OBSERVACAO == null ? '-/-' : order.DS_OBSERVACAO}</p>  
                          <p><Link to={`https://atacadomilitar.com.br/printer_order/index.php?cd=${window.btoa(unescape(encodeURIComponent(order.ID_DM009)))}&company=atacado`} target="_blank"><strong><FaPrint/> Reimprimir</strong></Link></p>
                          <br/>
                          <Collapse in={openIndex === index}>
                          <div id="example-collapse-text">
                            {jsGrade.map((item, itemIndex) => {
                              let itemTotal = 0;
                              return (
                                <>
                                  <p><strong>{item.name}</strong></p>
                                  <Table striped bordered hover key={itemIndex}>
                                    <thead>
                                      <tr>
                                        <th>Cor</th>
                                        <th>Tamanho</th>
                                        <th>Lado</th>
                                        <th>Quantidade</th>
                                        <th>Preço Unitário</th>
                                        <th>Total</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {Object.keys(item.quantities).sort((a, b) => {
                                        const colorA = colorList.find(color => color.ID_DM003 == a);
                                        const colorB = colorList.find(color => color.ID_DM003 == b);
                                        try {
                                          if(colorA.NM_COR < colorB.NM_COR) { return -1; }
                                          if(colorA.NM_COR > colorB.NM_COR) { return 1; }
                                        } catch {
                                          return 0;
                                        }
                                      }).map(colorId => {
                                        const color = colorList.find(color => color.ID_DM003 == colorId);
                                        return Object.keys(item.quantities[colorId]).map(sizeId => {
                                          const size = sizeList.find(size => size.ID_DM004 == sizeId);
                                          return Object.keys(item.quantities[colorId][sizeId]).map(laterality => {
                                            const quantity = item.quantities[colorId][sizeId][laterality];
                                            const total = parseFloat(item.price) * quantity;
                                            const unitPrice = parseFloat(item.price);
                                            itemTotal += total;
                                            orderTotal += total;
                                            return (
                                              <tr key={`${colorId}-${sizeId}-${laterality}`}>
                                                <td>{color ? color.NM_COR : 'Unknown color'}</td>
                                                <td>{size ? size.SG_TAMANHO : 'Unknown size'}</td>
                                                <td>{laterality === 'D' ? 'Destro' : laterality === 'C' ? 'Canhoto' : 'Neutro'}</td>
                                                <td>{quantity}</td>
                                                <td>{formatCurrencyBRL(unitPrice)}</td>
                                                <td style={{textAlign: 'right'}}>{formatCurrencyBRL(total)}</td>
                                              </tr>
                                            );
                                          });
                                        });
                                      })}
                                    </tbody>
                                    <tfoot>
                                      <tr>
                                        <td colSpan="5">Item Total:</td>
                                        <td>{formatCurrencyBRL(itemTotal)}</td>
                                      </tr>
                                    </tfoot>
                                  </Table>
                                </>
                              );
                            })}
                          </div>
                        </Collapse>
                          <p><strong>Total do Pedido:</strong> {formatCurrencyBRL(orderTotal)}</p>
                        </Col>
                      </Row>
                      <hr />
                    </>
                  )
                })
                : <p style={{ marginTop: '50px', textAlign: 'center', fontSize: '20px' }}>Nenhum pedido encontrado!</p>}
            </Col>
          </Row>
        </Container>
      </ContainerOrders>
      <Footer />
    </>
  );
}

export default Orders;
