import React, { useState, useEffect, useRef } from 'react';
import api from "../../services/api";
import Header from "../../components/Header";
import Banner from "../../components/Banner";
import ProductCard from "../../components/ProductCard";
import Footer from "../../components/Footer";

import { ContainerInfoText, Title } from "./style.js";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const AboutUs = () => {

  document.title = "Atacado Militar - Sobre Nós"


  return (
    <>

      <Header />
      <ContainerInfoText>
        <Container className="conShadown">
          <Title>Sobre Nós</Title>
          <br />
          <h2>Empresa</h2>
          <p>Bem-vindo a Atacado Militar!</p>

          <p>Atacado Militar é uma empresa atacadista voltada para o segmento militar com grande variedade de produtos nacionais e importados. Uma empresa que nasce em bases sólidas e com objetivos traçados antevendo as necessidades de novas tecnologias aliado a um amplo conhecimento de mercado.</p>

          <p>Produzimos e distribuímos produtos inovadores e de qualidade que irão superar as expectativas de nossos clientes e colaboradores. Superação que se dá através da qualificação de nossa equipe por treinamentos constantes, da obtenção de parcerias com transportadoras e da seleção criteriosa de fornecedores. Oferecendo produtos com o melhor custo-benefício do mercado que atendem às mais exigentes necessidades. A distribuição de nossos produtos é feita em todo o território nacional de maneira rápida e ágil. Fruto de parcerias estruturadas com colaboradores e fornecedores.</p>

          <p>A responsabilidade ambiental flui em nossas atividades em todo momento. Seja em atividades diárias, como escolha da matéria-prima, ou em nosso apoio a atividades ligadas com a natureza e incentivo à prática de esportes ao ar livre. Deixando para as próximas gerações ideais de valores e a consciência da preservação dos recursos naturais.</p>

          <h2>NOSSO COMPROMISSO</h2>
          <p>Algumas empresas valorizarem muito mais o número de vendas que fazem do que o relacionamento que firmam com seus clientes.</p>

          <p>A grande maioria das pessoas conhece ou conheceu atendentes simpáticos durante uma compra, que se transformam em distribuidores de grosserias caso o negócio lhe traga algum problema posterior.</p>

          <p>Por causa disso, nós da Atacado Militar temos um objetivo que vai além de fazer negócio. Vai além de vender nossos produtos. Temos um compromisso com ele, desde o primeiro contato, e nos comprometemos a entender suas necessidades. Queremos ajudá-lo a entender que aliar vendas com qualidade de atendimento é a melhor forma de transformar sua empresa e garantir melhores resultados. E nós vamos estar com ele para atingir todas essas metas.</p>

          <p>Nosso foco está na satisfação do cliente. Em estabelecer com ele um relacionamento além de “compra e venda”, valorizando seus desejos e tentando atendê-los da melhor forma possível. E é daí que acreditamos que vêm os melhores resultados.</p>

          <p>Tudo isso é possível, não é o mais fácil, é o mais prazeroso, mas só é possível com uma missão forte e comprometimento com as pessoas.</p>

          <h2>LOCALIZAÇÃO</h2>
          <p>Estamos localizados na Rua Fritz Lorenz, nº 1673, Sala 03. Bairro Industrial, na cidade de Timbó– SC. CEP 89120-000.</p>

          <p>Endereço virtual: <a href="http://www.atacadomilitar.com.br">www.atacadomilitar.com.br</a></p>

          <h2>MISSÃO</h2>
          <p>Oferecer serviços e produtos de qualidade visando à superação das expectativas de nossos clientes e colaboradores. Usando o comprometimento como meio para obtenção destes resultados. Além do apoio prestado às forças militares, que nos dá o orgulho e a certeza do dever cumprido por colaborar com a segurança nacional, papel de todo cidadão.</p>

          <p>Vale salientar que a Atacado Militar seleciona criteriosamente seus produtos e matéria-prima. Fato que comprova a mínima incidência de defeitos de fabricação gerando grande satisfação e o mínimo transtorno possível aos nossos clientes.</p>

          <br />

        </Container>
      </ContainerInfoText>
      <Footer />
    </>
  );
}

export default AboutUs;
