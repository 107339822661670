import React, { useState, useEffect, useRef } from 'react';
import api from "../../services/api";

import { IoAddOutline, IoRemoveOutline } from "react-icons/io5";
import { MdKeyboardDoubleArrowRight } from 'react-icons/md';

import { useParams, useNavigate } from "react-router-dom";
import { formatCurrencyBRL } from "./../../utils/formatCurrencyBRL";
import { verifyLogin } from "./../../utils/verifyLogin";

import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';

import { useCart } from './../../utils/CartContext';

import HistoryPages from "../../components/HistoryPages";

import Header from "../../components/Header";
import SelectDefault from '../../components/SelectDefault';

import Quill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import Footer from "../../components/Footer";

import { ContainerProduct, Title, ButtonBuy } from "./style.js";
import { ToastContainer, toast } from 'react-toastify';

import not_found_img from '../../imgs/logos/not_found.png';

const Product = () => {


  const { idDM005 } = useParams();

  const navigate = useNavigate();

  const [cdProduct, setCdProduct] = useState(0);
  const [nmProduct, setNmProduct] = useState('');
  const [vlProduct, setVlProduct] = useState(0);
  const [vlProductPromo, setVlProductPromo] = useState(0);
  const [colorImage, setColorImage] = useState({});
  const [colorList, setColorList] = useState([]);
  const [sizeList, setSizeList] = useState([]);
  const [colorSize, setColorSize] = useState({});
  const [colorSizePairs, setColorSizePairs] = useState([]);
  const [specifications, setSpecifications] = useState('');
  const [cdLaterality, setCdLaterality] = useState();
  const [cdLateralitySelected, setCdLateralitySelected] = useState();
  const [selectedArticle, setSelectedArticle] = useState();

  const [hover, setHover] = useState(false);
  const activeImageRef = useRef(null);

  const [quantities, setQuantities] = useState({});

  document.title = nmProduct;

  const sizeOrder = {
    'PP': 1,
    'P': 2,
    'M': 3,
    'G': 4,
    'GG': 5,
  };

  useEffect(() => {
    const dataFilter = {
      "ID_DM005": idDM005,
      "ID_DM013": "",
      "ID_DM013_PAI": "",
      "NM_PRODUTO": "",
      "IN_SITUACAO": "",
      "CD_PRODUTO": "",
      "ORDER_BY": false
    };

    api
      .post("filter_products", dataFilter)
      .then((response) => {
        if(response.data[0].IN_SITUACAO !== 'A'){
          navigate('/');
        }
        setCdProduct(response.data[0].CD_PRODUTO);
        setNmProduct(response.data[0].NM_PRODUTO);
        setVlProduct(response.data[0].VL_UNITARIO);
        setVlProductPromo(response.data[0].VL_UNITARIO_PROMOCIONAL);
        setColorImage(JSON.parse(response.data[0].JS_IMAGEM));
        setColorSize(response.data[0].JS_GRADE);
        setSpecifications(response.data[0].DS_ESPECIFICACAO);
        setCdLaterality(response.data[0].IN_LATERALIDADE)
        setSelectedArticle(response.data[0].IN_ARTIGO)

        if (response.data[0].IN_LATERALIDADE !== 'A') {
          setCdLateralitySelected(response.data[0].IN_LATERALIDADE)
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (colorSize && isJSON(colorSize)) {
      let colorSizePairsTemp = [];
      Object.entries(JSON.parse(colorSize)).forEach(([colorId, sizeIds]) => {
        sizeIds.forEach(sizeId => {
          colorSizePairsTemp.push([parseInt(colorId), sizeId]);
        });
      });
      setColorSizePairs(colorSizePairsTemp);
    }
  }, [colorSize]);

  function isJSON(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  useEffect(() => {
    let dataFilter = {
      "IN_ARTIGO": selectedArticle
    }

    api
      .post("filter_colors", dataFilter)
      .then((response) => {
        setColorList(response.data)
      })
      .catch((err) => {
        console.log(err)
      });

    api
      .post("filter_sizes", dataFilter)
      .then((response) => {
        const sortedSizes = response.data.sort((a, b) => sizeOrder[a.SG_TAMANHO] - sizeOrder[b.SG_TAMANHO]);
        setSizeList(sortedSizes)
      })
      .catch((err) => {
        console.log(err)
      });
  }, [selectedArticle]);

  const [activeImage, setActiveImage] = useState(0);
  const [imageList, setImageList] = useState([]);

  const nextImage = () => {
    setActiveImage((prev) => (prev === imageList.length - 1 ? 0 : prev + 1));
  };

  const prevImage = () => {
    setActiveImage((prev) => (prev === 0 ? imageList.length - 1 : prev - 1));
  };

  const isColorLinkedToAnySize = (colorId) => {
    return colorSizePairs.some(([colorIdInPair, _]) => colorId === colorIdInPair);
  };

  const isSizeLinkedToAnyColor = (sizeId) => {
    return colorSizePairs.some(([_, sizeIdInPair]) => sizeId === sizeIdInPair);
  };

  useEffect(() => {
    let allImages = [];
    let activeIndex = 0;

    // Pegar todas as imagens
    for (let colorKey in colorImage) {
      const colorValues = colorImage[colorKey];
      for (let key in colorValues) {
        if (key !== 'defaultImage' && typeof colorValues[key] === 'string') {
          allImages.push(colorValues[key]);
        }
      }
    }

    // Encontrar a imagem padrão e definir como ativa
    for (let colorKey in colorImage) {
      if ('defaultImage' in colorImage[colorKey]) {
        const colorValues = colorImage[colorKey];
        const defaultImageKey = colorValues.defaultImage.toString();
        const defaultImage = colorValues[defaultImageKey];
        activeIndex = allImages.indexOf(defaultImage);
        break;
      }
    }

    setImageList(allImages);
    if (activeIndex !== -1 && activeIndex < allImages.length) {
      setActiveImage(activeIndex);
    }
  }, [colorImage]);


  const handleAdd = (colorId, sizeId) => {
    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [`${colorId}-${sizeId}`]: (prevQuantities[`${colorId}-${sizeId}`] || 0) + 1,
    }));
  };

  const handleRemove = (colorId, sizeId) => {
    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [`${colorId}-${sizeId}`]: Math.max((prevQuantities[`${colorId}-${sizeId}`] || 0) - 1, 0),
    }));
  };

  const addProductCart = (setCartLength) => {
    const allQuantitiesZero = Object.values(quantities).every(qty => qty === 0);
    
    if (cdLateralitySelected && !allQuantitiesZero) {
      const filteredQuantities = Object.fromEntries(
        Object.entries(quantities).filter(([_, qty]) => qty > 0)
      );
    
      const dataProductCart = {
        'id': idDM005,
        'name': nmProduct,
        'laterality': cdLateralitySelected,
        'price': vlProductPromo && vlProductPromo !== '0.0000' ? vlProductPromo : vlProduct,
        'quantities': filteredQuantities
      };

      let productCartStorage;
      try {
        productCartStorage = JSON.parse(localStorage.getItem('productCart'));
      } catch (error) {
        console.error('Error parsing productCart data:', error);
        productCartStorage = null;
      }

      if (productCartStorage) {
        productCartStorage = [...productCartStorage, dataProductCart];
      } else {
        productCartStorage = [dataProductCart];
      }

      localStorage.setItem('productCart', JSON.stringify(productCartStorage));

      setCartLength(productCartStorage.length);
      toast.success("Item adicionado ao carrinho!");
      resetSelections();
    } else {
      toast.warning("Selecione as opções primeiramente ou verifique se a quantidade é maior que zero!");
    }
  };

  const resetSelections = () => {
    setQuantities({});
    if(cdLaterality != 'N'){
      selectLaterality('');
    }
  };

  const handleQuantityChange = (event, colorId, sizeId) => {
    const newQuantity = parseInt(event.target.value, 10) || 0;

    if (newQuantity < 0) {
      return;
    }
    
    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [`${colorId}-${sizeId}`]: newQuantity,
    }));
  };
  const { setCartLength } = useCart();

  const tableRef = useRef(null);
  const [showScrollIcon, setShowScrollIcon] = useState(false);

  // Verifica se a barra de rolagem horizontal existe
  const checkForScroll = () => {
    const table = tableRef.current;
    if (table) {
      setShowScrollIcon(table.scrollWidth > table.clientWidth);
    }
  };

  useEffect(() => {
    checkForScroll();
    window.addEventListener('resize', checkForScroll);

    return () => {
      window.removeEventListener('resize', checkForScroll);
    };
  }, []);

  // Conta quantos tamanhos foram vinculados a cores do produto, serve para exibir ou não a seta que indica que a tabela de cores x tamanhos tem scroll lateral
  const [linkedSizeCount, setLinkedSizeCount] = useState(0);
  useEffect(() => {
    if (sizeList != undefined && sizeList.length > 0 && colorSizePairs != undefined && colorSizePairs.length > 0) {
      const linkedSizes = sizeList.filter((size) => isSizeLinkedToAnyColor(size["ID_DM004"]));
      setLinkedSizeCount(linkedSizes.length);
    }
  }, [sizeList, colorSizePairs]);

  // Ateração na seleção de lateralidade
  const selectLaterality = (value) => {
    setCdLateralitySelected(value);
  };

  const tableDivRef = useRef(null);
  const [tableHeight, setTableHeight] = useState(0);

  useEffect(() => {
    if (tableDivRef.current) {
      setTableHeight(tableDivRef.current.offsetHeight);
    }
  }, []);

  return (
    <>
      <Header />
      <ContainerProduct>
        <Container className="conShadown" translate="no">
          <HistoryPages />
          <Row>
            <Col sm={6} className="previewImages">
              <div className="product-details">
                <div className="carousel">
                  <AiOutlineLeft className="arrow left" onClick={prevImage} />
                  <img
                    ref={activeImageRef}
                    src={imageList[activeImage] ? imageList[activeImage] : not_found_img}
                    alt="product"
                    className={`active-image${hover ? " zoom" : ""}`}
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                  />
                  <AiOutlineRight className="arrow right" onClick={nextImage} />
                </div>
                <div className="thumbnails">
                  {imageList.map((img, index) => (
                    (typeof img === 'string' && (img.startsWith("http") || img.startsWith("https"))) && (
                      <img
                        key={index}
                        src={img}
                        alt="thumbnail"
                        className={index === activeImage ? 'thumbnail active' : 'thumbnail'}
                        onClick={() => setActiveImage(index)}
                      />
                    )
                  ))}
                </div>
              </div>
            </Col>
            <Col sm={6}>
              <Title translate="no">{nmProduct}</Title>
              <span style={{ 'color': '#202020', 'fontSize': '14px' }} translate="no">Código: {cdProduct}</span>
              {
                verifyLogin() &&
                  vlProductPromo && vlProductPromo !== '0.0000' ?
                  <>
                    <p translate="no" className='price'><span className='line-through'>{formatCurrencyBRL(vlProduct)}</span> &nbsp;&nbsp; {formatCurrencyBRL(vlProductPromo)}</p>
                  </>
                : 
                  <p translate="no" className='price'>{verifyLogin() && formatCurrencyBRL(vlProduct)}</p>
              }
              <div className="handedness">
                <p>{cdLaterality === 'N' ? '' : 'Lado'}</p>
                {
                  cdLaterality === 'D' ? <p>Destro</p> :
                    cdLaterality === 'C' ? <p>Canhoto</p> :
                      cdLaterality === 'N' ? <p></p> :
                        <>
                          <div
                            className={`handedness-square ${cdLateralitySelected === 'C' ? 'handedness-square-selected' : ''}`}
                            onClick={() => selectLaterality('C')}
                          >
                            Canhoto
                          </div>
                          <div
                            className={`handedness-square ${cdLateralitySelected === 'D' ? 'handedness-square-selected' : ''}`}
                            onClick={() => selectLaterality('D')}
                          >
                            Destro
                          </div>
                        </>
                }
              </div>
              <div className='selectSizeColors'>
                <h3>Selecione as Variações:</h3>
                <div className='tableColorSize'>
                  <div className='table'>
                    <Table striped bordered variant="light">
                      <thead>
                        <tr>
                          <th></th>
                          {sizeList
                            .filter((size) => isSizeLinkedToAnyColor(size["ID_DM004"]))
                            .map((size, index) => (
                              <th key={index} translate="no">{size["SG_TAMANHO"]}</th>
                            ))}
                        </tr>
                      </thead>
                      <tbody>
                      {colorList
                        .filter((color) => isColorLinkedToAnySize(color["ID_DM003"]))
                        .map((color, index) => {
                          const sizesForColor = JSON.parse(colorSize);
                          if(!sizesForColor[color["ID_DM003"]].includes(-2)){
                            return (
                              <tr key={index}>
                                <td
                                  translate="no"
                                  className="colorName"
                                  style={{ cursor: "pointer" }}
                                >
                                  {color["NM_COR"]}
                                </td>
                                {sizeList
                                  .filter((size) => isSizeLinkedToAnyColor(size["ID_DM004"]))
                                  .map((size, index) => {
                                    const isColorSizeLinked = colorSizePairs.some(
                                      ([colorId, sizeId]) =>
                                        colorId === color["ID_DM003"] && sizeId === size["ID_DM004"]
                                    );

                                    return (
                                      <td key={index}>
                                        {isColorSizeLinked ? (
                                          <div className="optValue">
                                            <IoRemoveOutline onClick={() => handleRemove(color["ID_DM003"], size["ID_DM004"])} />
                                            <input
                                              value={quantities[`${color["ID_DM003"]}-${size["ID_DM004"]}`] || 0}
                                              readOnly={false}
                                              onChange={(event) => handleQuantityChange(event, color["ID_DM003"], size["ID_DM004"])}
                                            />
                                            <IoAddOutline onClick={() => handleAdd(color["ID_DM003"], size["ID_DM004"])} />
                                          </div>
                                        ) : (
                                          <div className="placeholder"></div>
                                        )}
                                      </td>
                                    );
                                  })}
                              </tr>
                            );
                            }
                          })}
                      </tbody>
                    </Table>
                  </div>
                  {linkedSizeCount > 5 && <MdKeyboardDoubleArrowRight className="arrow-indication" />}
                </div>
              </div>
              {
                verifyLogin() ?
                  <ButtonBuy onClick={() => addProductCart(setCartLength)}>
                    Adicionar ao Carrinho
                  </ButtonBuy>
                  :
                  <p style={{ marginTop: '30px', textAlign: 'center', color: '#e30309', fontWeight: 'bold', fontSize: '18px' }}>Faça login para ver os preços e efetuar compras</p>
              }
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <div className="detailsProduct" translate="no">
                <Quill
                  value={specifications}
                  readOnly={true}
                  theme={null}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </ContainerProduct >
      <ToastContainer />
      <Footer />
    </>
  );
}

export default Product;
