import styled from "styled-components";
import { colors } from "../../globalStyle";

export const ContainerOrders = styled.div`
    background-color: ${colors.background2};
    background-position: top center;
    background-repeat: repeat;
    padding-bottom: 60px;

    .container {
        background-color: #fff;
        padding: 15px;
    }

    .orderCard {
        padding: 10px;
        border-radius: 7px;
        margin-top: 20px;
        background-color: #f8f8f8;
        cursor: pointer;
    }

    .orderCard > p {
        margin-bottom: 1em;
        text-align: left;
    }

    .orderCard table {
        width: 100%;
    }

    .orderCard table th, 
    .orderCard table td {
        text-align: left;
        padding: 0.5em;
    }

    .orderCard table th {
        background-color: #2e894a;
        color: #fff;
    }

    .orderCard table td:nth-child(4) {
        text-align: right;
    }

    .orderCard table tfoot td {
        font-weight: bold;
        text-align: right;
    }

    .actionBtn {
        cursor: pointer;
        margin: 10px;
        font-size: 20px;
    }

`;

export const Title = styled.h2`
    padding-top: 25px;
    font-weight: 600;
    font-size: 20px;

    svg {
        margin-right: 10px;
        font-size: 1.2rem;
        margin-bottom: 5px;
    }
`;
