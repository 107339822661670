import React, { useState, useEffect, useRef } from 'react';
import api from "../../services/api";
import Header from "../../components/Header";
import Banner from "../../components/Banner";
import ProductCard from "../../components/ProductCard";
import Footer from "../../components/Footer";

import { ContainerInfoText, Title } from "./style.js";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const HowToBuy = () => {

  document.title = "Atacado Militar - Como Comprar"

  return (
    <>

      <Header />
      <ContainerInfoText>
        <Container className="conShadown">
          <Title>Como Comprar</Title>
          <br />
          <p>Nossa empresa é exclusiva para atacado não sendo possível comprar para consumo próprio. Venda exclusiva para CNPJ. Nosso pedido mínimo é de R$ 800,00 e pagamento a vista.</p>
          <br />
          <ol>
            <li>Escolha o produto que deseja comprar.</li>
            <li>Clique no botão de "Agregar ao carrinho" ou "Comprar". O seu produto será adicionado ao carrinho e você poderá seguir comprando, caso desejar.</li>
            <li>Você pode seguir agregando outros produtos ao carrinho ou, então, clicar em "Finalizar Compra".</li>
            <li>Complete com seus dados de contato e clique em "Continuar".</li>
            <li>Coloque o endereço de onde você deseja receber o produto. Logo clique em "Continuar".</li>
            <li>Selecione a forma de frete que desejar e clique em "Continuar".</li>
            <li>Escolha o meio de pagamento. <br/> - Uma vez que você tenha escolhido o meio de pagamento, clique "Continuar".</li>
            <li>Finalmente na Confirmação da compra você pode revisar toda a informação sobre a mesma, inclusive os seus dados. Logo clique em "Continuar".</li>
            <li>Você será redirigido para outra tela para que complete os dados sobre a forma de pagamento escolhida. Depois de confirmar a compra receberá um email da nossa parte, esse não será um comprovante de pagamento.</li>
            <li>Uma vez creditado o pagamento, faremos um envio correspondente dos produtos que você comprou.</li>
          </ol>
          <br />
        </Container>
      </ContainerInfoText>
      <Footer />
    </>
  );
}

export default HowToBuy;
