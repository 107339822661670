import * as Yup from 'yup';
import { React, useState, useEffect } from 'react';

import 'react-toastify/dist/ReactToastify.css';
// Mui Components
import { Button, Checkbox, Dialog, FormControlLabel, Grid, Stack, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

//RHForm
import {
    useForm,
    Controller,
    FormProvider,
} from 'react-hook-form';

// services
import api from '../../services/api';

export default function ModalAddAddress({
    modalStatus,
    closeModelFunction,
    addNewAddressFunction,
    ...rest
}) {

    const UpdateAddressSchema = Yup.object().shape({
        NM_ENDERECO: Yup.string()
            .min(3, 'Nome do Endereço deve ter no mínimo 3 caracteres')
            .max(100, 'Nome do Endereço deve ter no máximo 100 caracteres')
            .required('Nome do Endereço é obrigatório'),

        NR_CEP: Yup.string()
            .matches(/^[0-9]{5}-[0-9]{3}$/, 'CEP deve estar no formato 00000-000')
            .required('CEP é obrigatório'),

        DS_LOGRADOURO: Yup.string()
            .min(3, 'Rua deve ter no mínimo 3 caracteres')
            .max(100, 'Rua deve ter no máximo 100 caracteres')
            .required('Rua é obrigatório'),

        NR_PREDIO: Yup.string()
            .matches(/^[0-9]+$/, 'Número do Prédio deve conter apenas números')
            .required('Número do Prédio é obrigatório'),

        NM_BAIRRO: Yup.string()
            .min(3, 'Bairro deve ter no mínimo 3 caracteres')
            .max(100, 'Bairro deve ter no máximo 100 caracteres')
            .required('Bairro é obrigatório'),

        NM_CIDADE: Yup.string()
            .min(3, 'Cidade deve ter no mínimo 3 caracteres')
            .max(100, 'Cidade deve ter no máximo 100 caracteres')
            .required('Cidade é obrigatório'),

        NM_CONTATO: Yup.string()
            .matches(/^[A-Za-z\s]+$/, 'Contato deve conter apenas letras e espaços')
            .required('Contato é obrigatório'),

        DS_COMPLEMENTO: Yup.string()
            .max(100, 'Complemento deve ter no máximo 100 caracteres'),

        DS_REFERENCIA: Yup.string()
            .max(100, 'Referência deve ter no máximo 100 caracteres'),
    });

    const defaultValues = {
        NM_ENDERECO: '',
        NR_CEP: '',
        DS_LOGRADOURO: '',
        NR_PREDIO: '',
        NM_BAIRRO: '',
        DS_COMPLEMENTO: '',
        NM_CIDADE: '',
        DS_REFERENCIA: '',
        IN_PADRAO: 'S',
        NM_CONTATO: '',
        IN_SITUACAO: "A",
    };

    const methods = useForm({
        defaultValues,
    });

    const { handleSubmit, control, formState: { errors, isSubmitting }, setValue, setError } = methods;

    const zipCodeMask = (value) => {
        return value
            .replace(/\D/g, '')
            .replace(/(\d{5})(\d)/, '$1-$2')
            .replace(/(-\d{3})\d+?$/, '$1');
    };

    const removeZipCodeMask = (value) => {
        return value.replace(/\D/g, '');
    }

    const [idDM001, setIdDM001] = useState(0);

    useEffect(() => {
        const lsClient = JSON.parse(localStorage.getItem('userDataLogin'))
        setIdDM001(lsClient.ID_DM001)
    }, []);

    const onSubmit = async (data) => {
        try {
            await UpdateAddressSchema.validate(data, { abortEarly: false });
            const dataAddressRegister = {
                ID_DM001: idDM001,
                TP_ENDERECO: "1",
                NM_ENDERECO: data.NM_ENDERECO,
                NR_CEP: removeZipCodeMask(data.NR_CEP),
                DS_LOGRADOURO: data.DS_LOGRADOURO,
                NR_PREDIO: data.NR_PREDIO,
                NM_BAIRRO: data.NM_BAIRRO,
                DS_COMPLEMENTO: data.DS_COMPLEMENTO || "N/A",
                NM_CIDADE: data.NM_CIDADE,
                DS_REFERENCIA: data.DS_REFERENCIA || "N/A",
                IN_PADRAO: data.IN_PADRAO === true ? "S" : "N",
                NM_CONTATO: data.NM_CONTATO,
                IN_SITUACAO: "A",
            }
            addNewAddressFunction(dataAddressRegister);
            closeModelFunction();

        } catch (validationErrors) {
            validationErrors.inner.forEach((error) => {
                console.log(error);
                setError(error.path, {
                    type: "manual",
                    message: error.message,
                });
            });
        }
    };

    return (
        <>
            <Dialog fullWidth maxWidth="sm" open={modalStatus} onClose={() => closeModelFunction()}>
                <Stack sx={{ p: 3 }}>
                    <Typography variant="h6">Novo Endereço</Typography>
                </Stack>

                <FormProvider methods={methods}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={2} sx={{ p: 3 }}>
                            <Grid item md={12} >
                                <FormControlLabel
                                    label="Endereço Padrão"
                                    control={
                                        <Controller
                                            name="IN_PADRAO"
                                            control={control}
                                            render={({ field }) => <Checkbox {...field} checked={field.value} />}
                                        />
                                    }
                                />
                            </Grid>
                            <Grid item md={12} >
                                <Controller
                                    name="NM_ENDERECO"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            label="Nome do Endereço"
                                            variant="outlined"
                                            fullWidth
                                            value={field.value}
                                            onChange={field.onChange}
                                            error={!!errors.NM_ENDERECO}
                                            helperText={errors.NM_ENDERECO?.message}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item md={6} >
                                <Controller
                                    name="NR_CEP"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            label="CEP"
                                            variant="outlined"
                                            fullWidth
                                            InputProps={{ inputProps: { maxLength: 9 } }}
                                            value={field.value}
                                            onChange={(event) => { setValue('NR_CEP', zipCodeMask(event.target.value)) }}
                                            error={!!errors.NR_CEP}
                                            helperText={errors.NR_CEP?.message}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item md={6} >
                                <Controller
                                    name="DS_LOGRADOURO"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            label="Rua"
                                            variant="outlined"
                                            fullWidth
                                            value={field.value}
                                            onChange={field.onChange}
                                            error={!!errors.DS_LOGRADOURO}
                                            helperText={errors.DS_LOGRADOURO?.message}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item md={6} >
                                <Controller
                                    name="NR_PREDIO"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            label="Número do Prédio"
                                            variant="outlined"
                                            fullWidth
                                            value={field.value}
                                            onChange={field.onChange}
                                            error={!!errors.NR_PREDIO}
                                            helperText={errors.NR_PREDIO?.message}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item md={6} >
                                <Controller
                                    name="NM_BAIRRO"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            label="Bairro"
                                            variant="outlined"
                                            fullWidth
                                            value={field.value}
                                            onChange={field.onChange}
                                            error={!!errors.NM_BAIRRO}
                                            helperText={errors.NM_BAIRRO?.message}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item md={6} >
                                <Controller
                                    name="DS_COMPLEMENTO"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            label="Complemento"
                                            variant="outlined"
                                            fullWidth
                                            value={field.value}
                                            onChange={field.onChange}
                                            error={!!errors.DS_COMPLEMENTO}
                                            helperText={errors.DS_COMPLEMENTO?.message}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item md={6} >
                                <Controller
                                    name="NM_CIDADE"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            label="Cidade"
                                            variant="outlined"
                                            fullWidth
                                            value={field.value}
                                            onChange={field.onChange}
                                            error={!!errors.NM_CIDADE}
                                            helperText={errors.NM_CIDADE?.message}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item md={6} >
                                <Controller
                                    name="DS_REFERENCIA"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            label="Referência"
                                            variant="outlined"
                                            fullWidth
                                            value={field.value}
                                            onChange={field.onChange}
                                            error={!!errors.DS_REFERENCIA}
                                            helperText={errors.DS_REFERENCIA?.message}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item md={6} >
                                <Controller
                                    name="NM_CONTATO"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            label="Contato"
                                            variant="outlined"
                                            fullWidth
                                            value={field.value}
                                            onChange={field.onChange}
                                            error={!!errors.NM_CONTATO}
                                            helperText={errors.NM_CONTATO?.message}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item md={12}>
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                >
                                    <Button variant="outlined" onClick={closeModelFunction}>
                                        Cancelar
                                    </Button>
                                    <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                                        Salvar Endereço
                                    </LoadingButton>
                                </Stack>
                            </Grid>
                        </Grid>
                    </form>
                </FormProvider>
            </Dialog>
        </>
    );
}
