import styled from "styled-components"
import { colors } from "../../globalStyle";

export const ContainerAccount = styled.div`
    background-color: ${colors.background2};
    background-position: top center;
    background-repeat: repeat;
    padding-bottom: 60px;
    
    .container {
        background-color: #fff;
        padding: 15px;
    }

    input {
        margin: 10px 0px 10px 0px !important;
    }

    .loginDiv{
        padding: 20px;
    }

    .forgottenPass {
        
    }

    .menuUser p {
        transition: 0.5s;
        margin-top: 10px;
        padding: 10px;
        background: #ededed;
        border-radius: 10px;
    }

    .menuUser {
        transition: 0.5s;
        cursor: pointer;
    }

    .menuUser :hover {
        transition: 0.5s;
        margin-left: 10px;

        a {
            margin: 0px !important;
        }
    }

    .checkTerms {
        display: flex;
        align-items: center;
    
        label {
            margin-left: 10px;
            margin-bottom: -2px;
        }
    }

`;

export const Title = styled.h2`
    padding-top: 25px;
    font-weight: 600;
    font-size: 20px;

    svg {
        margin-right: 10px;
        font-size: 1.2rem;
        margin-bottom: 5px;
    }
`;

