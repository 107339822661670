import Header from "../../components/Header";
import Footer from "../../components/Footer";

import { ContainerInfoText, Title } from "./style.js";

import Container from 'react-bootstrap/Container';

const PrivacyPolicy = () => {

  document.title = "Atacado Militar - Política de Privacidade"

  return (
    <>
      <Header />
      <ContainerInfoText>
        <Container className="conShadown">
          <h1>Política de Privacidade - Atacado Militar</h1>
          <p><strong>Última atualização:</strong> 14/08/2024</p>
          
          <h2>1. Introdução</h2>
          <p>A Atacado Militar valoriza a privacidade dos seus usuários e está comprometida em proteger as informações pessoais coletadas através do nosso site. Esta Política de Privacidade descreve como coletamos, usamos, armazenamos e protegemos suas informações quando você visita o nosso site <a href="https://www.atacadomilitar.com.br">www.atacadomilitar.com.br</a> e utiliza nossos serviços.</p>
          
          <h2>2. Informações que Coletamos</h2>
          <p>Coletamos diferentes tipos de informações, incluindo:</p>
          <ul>
              <li><strong>Informações Pessoais:</strong> Nome, e-mail, telefone, endereço, e outras informações de identificação que você fornece voluntariamente ao preencher formulários no site.</li>
              <li><strong>Informações de Navegação:</strong> Dados sobre sua visita ao site, como endereço IP, tipo de navegador, páginas visitadas, tempo gasto em cada página, e outras estatísticas relacionadas.</li>
              <li><strong>Cookies e Tecnologias Semelhantes:</strong> Utilizamos cookies para melhorar sua experiência no site, lembrar suas preferências e coletar dados de análise.</li>
          </ul>
          
          <h2>3. Como Usamos Suas Informações</h2>
          <p>As informações coletadas são usadas para:</p>
          <ul>
              <li>Fornecer, operar e manter nossos serviços;</li>
              <li>Melhorar e personalizar sua experiência no site;</li>
              <li>Enviar comunicações, como newsletters, ofertas especiais, e outras informações que possam ser do seu interesse;</li>
              <li>Analisar e compreender como nosso site é usado para melhorar nossos serviços;</li>
              <li>Cumprir obrigações legais e regulamentares.</li>
          </ul>
          
          <h2>4. Compartilhamento de Informações</h2>
          <p>Nós não vendemos, trocamos ou alugamos suas informações pessoais para terceiros. Podemos compartilhar suas informações com:</p>
          <ul>
              <li><strong>Fornecedores de Serviços:</strong> Terceiros que nos ajudam a operar nosso site e fornecer serviços a você, como hospedagem de site, análise de dados, e marketing.</li>
              <li><strong>Cumprimento Legal:</strong> Em resposta a uma ordem judicial, intimação, ou outro processo legal, ou para proteger nossos direitos, propriedade e segurança.</li>
          </ul>
          
          <h2>5. Proteção de Dados</h2>
          <p>Adotamos medidas de segurança apropriadas para proteger suas informações contra acesso, alteração, divulgação ou destruição não autorizada. No entanto, nenhum método de transmissão pela Internet ou armazenamento eletrônico é 100% seguro, e não podemos garantir segurança absoluta.</p>
          
          <h2>6. Seus Direitos</h2>
          <p>Você tem o direito de:</p>
          <ul>
              <li>Acessar, corrigir ou excluir suas informações pessoais;</li>
              <li>Solicitar a restrição ou objeção ao processamento de suas informações;</li>
              <li>Retirar seu consentimento a qualquer momento, quando o processamento for baseado no consentimento;</li>
              <li>Solicitar a portabilidade de suas informações para outro controlador de dados.</li>
          </ul>
          <p>Para exercer esses direitos, entre em contato conosco através do e-mail <a href="mailto:vendas@atacadomilitar.com.br">vendas@atacadomilitar.com.br</a></p>
          
          <h2>7. Cookies</h2>
          <p>Nosso site utiliza cookies para melhorar a experiência do usuário. Você pode configurar seu navegador para recusar cookies, mas isso pode afetar a funcionalidade do site.</p>
          
          <h2>8. Links para Sites de Terceiros</h2>
          <p>Nosso site pode conter links para sites de terceiros. Não somos responsáveis pelas práticas de privacidade ou pelo conteúdo desses sites. Recomendamos que você leia as políticas de privacidade de qualquer site de terceiros que visitar.</p>
          
          <h2>9. Alterações a Esta Política de Privacidade</h2>
          <p>Podemos atualizar esta Política de Privacidade periodicamente. Notificaremos sobre quaisquer mudanças significativas através do nosso site. Recomendamos que você revise esta política regularmente para estar ciente de qualquer atualização.</p>
          
          <h2>10. Contato</h2>
          <p>Se você tiver dúvidas ou preocupações sobre esta Política de Privacidade, entre em contato conosco através do e-mail <a href="mailto:vendas@atacadomilitar.com.br">vendas@atacadomilitar.com.br</a></p>
          
          <p>Atacado Militar | Rua Fritz Lorenz, 1673, Sala 2, Industrial, Timbó – SC, 89120-000 | (47) 3333-1682 | <a href="mailto:vendas@atacadomilitar.com.br">vendas@atacadomilitar.com.br</a></p>
        </Container>
      </ContainerInfoText>
      <Footer />
    </>
  );
}

export default PrivacyPolicy;
