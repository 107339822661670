import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;

  div{
    text-align:center;
  }

  img{
    width: 250px;
    margin-bottom: 20px;
  }

  h1, h2{
    color: var(--primary-color);
  }

  h1{
    font-size: 40px;
    font-weight: 600;
  }

  h2{
    margin-top: 30px;
    font-size: 30px;
  }
  
`;
