import React from 'react';
import { Button } from "./style.js";

const ButtonDefault = ( {Width, Margin, Text, ...rest} ) => {

  return (
    <>
        <Button Width={Width} Margin={Margin} {...rest}>{Text}</Button>
    </>
  );
}

export default ButtonDefault;
