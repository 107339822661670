import React, { useState, useEffect, useRef } from 'react';
import api from "../../services/api";

import { ContainerBanner } from "./style.js";
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Carousel from 'react-bootstrap/Carousel';
import { Link } from "react-router-dom";

import { FaCheckCircle, FaClock, FaShoppingBasket, FaBoxOpen } from "react-icons/fa";

const Banner = () => {

  const [bannerList, setBannerList] = useState([]);

  useEffect(() => {
    api
      .get("get_all_banners")
      .then((response) => {
        setBannerList(response.data)
      })
      .catch((err) => {
        console.log(err)
      });
  }, []);

  return (
    <>
      <ContainerBanner>
        <Carousel>
          {bannerList.map((banner, index) => (
            <Carousel.Item key={index}>
              <Link to={banner.DS_LINK}>
                <img
                  className="d-block w-100"
                  src={banner.DS_PATH}
                  alt={banner.NM_BANNER}
                />
              </Link>
            </Carousel.Item>
          ))}
        </Carousel>
        <div className="detailsShop">
          <Container style={{'padding': '0px'}}>
            <Row>
              <Col sm={12} md={3} className='detailsItem'>
                <FaCheckCircle />
                <p><span>Compra Segura</span><br/>100%</p>
              </Col>
              <Col sm={12} md={3} className='detailsItem'>
                <FaClock />
                <p><span>Horário de Atendimento</span><br/>Segunda à Sexta das 7:30h às<br/> 12h e das 13:30h às 17:30h</p>
              </Col>
              <Col sm={12} md={3} className='detailsItem'>
                <FaShoppingBasket />
                <p><span>Loja de Atacado</span><br/>Visualização de Preços<br/> Mediante Cadastro</p>
              </Col>
              <Col sm={12} md={3} className='detailsItem'>
                <FaBoxOpen />
                <p><span>Pedido Mínimo</span><br/>Primeira Compra R$ 800,00 <br/> Obrigatório Possuir CNPJ</p>
              </Col>
            </Row>
          </Container>
        </div>
      </ContainerBanner>
    </>
  );
}

export default Banner;
