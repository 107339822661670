export const formatCurrencyBRL = (value) => {
  const formatter = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
  });
  if (value !== undefined && value > 0) {
      return formatter.format(value);
  }
  return formatter.format(0);
};

export const formatCurrencyFloat = (value) => {
  const cleanedValue = value.replace(/[R$\s]/g, '').replace(',', '.');
  const floatValue = parseFloat(cleanedValue);
  return parseFloat(floatValue.toFixed(2));
};
