import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';

import { FaHome } from "react-icons/fa";

import { ContainerHistory } from "./style.js";

const HistoryComponent = () => {
    const location = useLocation();
    const [currentPath, setCurrentPath] = useState(location.pathname);
    const [lastPath, setLastPath] = useState(localStorage.getItem('previousPath') || location.pathname);

    useEffect(() => {
        setLastPath(localStorage.getItem('previousPath') || currentPath);

        if (localStorage.getItem('previousPath') !== currentPath) {
            localStorage.setItem('previousPath', currentPath);
        }

        if (location.pathname !== currentPath) {
            setCurrentPath(location.pathname);
        }

    }, [location.pathname, currentPath]);

    const formatPath = (path) => {
        if (!path) return "";
        const parts = path.split('/');
        const lastPart = parts[parts.length - 1];
        return decodeURIComponent(lastPart.replace(/-/g, ' '));
    }

    return (
        <ContainerHistory>
            <p>
                <Link to="/"><FaHome /></Link>
                &nbsp;&nbsp;/&nbsp;&nbsp;
                <Link to={lastPath || ''}>{formatPath(lastPath)}</Link>
                &nbsp;&nbsp;/&nbsp;&nbsp;
                <Link to={currentPath || ''}>{formatPath(currentPath)}</Link>
            </p>
        </ContainerHistory>
    );
};

export default HistoryComponent;
