import {React, useState, useEffect}from 'react';
import api from "../../../services/api"

import { useNavigate } from "react-router-dom";

import {ContainerModal} from "./style.js";

import ButtonDefault from '../../ButtonDefault';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import styled from '@emotion/styled';
import { css } from '@emotion/react';


export default function ModalDeleteAddress({modalStatus, closeModelFunction, detailsAddress, DeleteAddressAccount,  ...rest }) {  

  const navigate = useNavigate();

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,
    p: 4,
    borderRadius: '10px',
    padding: '20px'
  };
  
  const styleInputs = {
    margin: "10px"
  }

  const DeleteAddressProcess = () => {
    DeleteAddressAccount(detailsAddress)
    closeModelFunction()
  }

  return (
    <>
      <Modal
        open={modalStatus}
        onClose={closeModelFunction}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ContainerModal>
              <div>
                <h3>Deseja deletar o endereço #{detailsAddress}?</h3>

                <div style={{"marginTop":"20px"}}>
                  <ButtonDefault Margin={styleInputs.margin} Width={'200px'} Text='Continuar' onClick={e=> DeleteAddressProcess()}/>
                  <ButtonDefault Margin={styleInputs.margin} Width={'200px'} style={{"float":"right"}} Text='Cancelar' onClick={closeModelFunction}/>
                </div>
              </div>
          </ContainerModal>
        </Box>
      </Modal>
    </>
  );
}
