import styled from "styled-components"

export const ContainerSelect = styled.div`
    width: ${props => `${props.Width}`};
    margin: ${props => `${props.Margin}`};
    label {
        font-size: 15px;
        font-weight: 600;

        span {
            font-weight: 1.3em;
            color: red;
        }
    }
`;

export const Select = styled.select`
    border-top:0px;
    border-left:0px;
    border-right:0px;
    border-bottom: 1px solid #44444 !important;
    
    font-size: 18px;
    margin: 0px !important;
    width: 100%;
    height: 37px;
    border-radius: 5px;
`;

