import React, { useState, useEffect, useRef } from 'react';
import api from "../../services/api";
import Header from "../../components/Header";
import Banner from "../../components/Banner";
import ProductCard from "../../components/ProductCard";
import Footer from "../../components/Footer";

import { ContainerInfoText, Title } from "./style.js";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const HowToBuy = () => {

  document.title = "Atacado Militar - Trocas e Devoluções"

  return (
    <>

      <Header />
      <ContainerInfoText>
        <Container className="conShadown">
          <Title>Trocas e Devoluções</Title>
          <br />
          <h2>GARANTIA</h2>
          <p>Todos os produtos possuem garantia, dentro dos prazos legais, sobre defeitos de fabricação desde que não tenham sido danificados por mau uso ou não ter seguido as informações para conservação constantes nas etiquetas ou embalagens. No caso de defeito de fabricação os custos de devolução e reenvio correm por conta da ATACADO MILITAR.</p>

          <h2>POLÍTICA DE TROCAS E DEVOLUÇÕES</h2>
          <p>As ocorrências que envolvem trocas e devoluções devem sempre ser previamente comunicadas a nossa equipe. </p>
          <br />

          <p>• Devolução por Arrependimento / Desistência</p>

          <p>Se você se arrepender ou desistir de uma compra, informe o setor de vendas, não receberemos devoluções sem prévio aviso. Se a devolução for autorizada, nos remeta a mercadoria em até 7 dias úteis a contar do recebimento, efetuando os seguintes passos:</p>

          <p>- O produto não poderá conter indícios de uso;</p>

          <p>- O produto deverá ser encaminhado preferencialmente na embalagem original, acompanhado de nota fiscal;</p>

          <p>- Ao efetuar o processo de devolução o cliente deverá no verso da nota fiscal a ser devolvida, informar o motivo da recusa/devolução, o nome de quem está devolvendo, CPF e a data da devolução;</p>

          <p>- O frete de devolução fica por conta de quem está devolvendo a mercadoria.</p>



          <p>• Produto com defeito ou falta de mercadoria</p>

          <p>Se ao receber o pedido, você identificar qualquer defeito comunique imediatamente nosso setor de vendas, para orientação dos procedimentos a serem adotados. O mesmo vale para a falta de algum item no pedido. O prazo máximo para informar os desacordos é de sete dias, a contar da data de entrega.</p>



          <p>• Avaria ou violação na embalagem</p>

          <p>Se na entrega do produto houver avaria aparente ou a embalagem estiver violada você deverá conferir toda a mercadoria no recebimento. Se houver algum desacordo, a mesma deverá ser recusada e o nosso setor de vendas deverá ser informado imediatamente.</p>

          <p>Caso um terceiro autorizado (porteiro, empregado, recepção, etc.) receba a entrega do seu pedido ele deverá estar apto a checar as condições do produto recebido e recusar caso haja desacordos.</p>

          <p>Não nos responsabilizamos por mercadorias recebidas com embalagem violada ou com indício de violação, caso haja desacordos e não for realizada a conferência no recebimento.</p>



          <p>• Recusa no ato da entrega</p>

          <p>No ato da entrega do pedido informe ao transportador o motivo da recusa, anote o mesmo no verso da NF e assine. É de extrema importância você comunicar nosso setor de vendas imediatamente sobre a recusa.</p>



          <p>• Reembolso/ Reenvio de mercadoria</p>

          <p>Para solicitação de reembolso e reenvio de mercadoria entre em contato com nosso setor de vendas para orientação dos procedimentos a serem adotados.</p>
          <br />
        </Container>
      </ContainerInfoText>
      <Footer />
    </>
  );
}

export default HowToBuy;
